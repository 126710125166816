/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

$('.gallery-item').magnificPopup({
  type: 'image',
  gallery:{
    enabled:true
  }
});

  
    var win = $(window),
    nav = $('.navigation-wrapper'),

    pos = nav.offset().top,
    sticky = function(){
      win.scrollTop() > pos ? nav.addClass('sticky') : nav.removeClass('sticky'); // jshint ignore:line
      //win.scrollTop() > pos ? $('.banner').addClass('sticky') : $('.banner').removeClass('sticky'); // jshint ignore:line
    };


win.scroll(sticky);


$.fn.slideFadeToggle  = function(speed, easing, callback) {
        return this.animate({opacity: 'toggle', height: 'toggle'}, 300);
}; 
    // Toggle open and close nav styles on click
    $('#nav-toggle').click(function(e) {
      e.preventDefault();
      $('.navigation-wrapper').slideFadeToggle();
      $(this).find('.nav-toggle-bars').toggleClass('active');
    });




$('.header-slider').slick({
  nextArrow: '<i class="fa fa-chevron-right slick-arrow arrow-right"></i>',
  prevArrow: '<i class="fa fa-chevron-left slick-arrow arrow-left"></i>',
  autoplay: true,
  autoplaySpeed: 7000,
  fade: true,
});

$('.gallery-slider').slick({
  nextArrow: '<i class="fa fa-chevron-right slick-arrow arrow-right"></i>',
  prevArrow: '<i class="fa fa-chevron-left slick-arrow arrow-left"></i>',
  autoplay: false,
  autoplaySpeed: 7000,
  fade: true,
});

  


if ($('body').hasClass('paged')) {

  if ($(window).width() > 991) {
    banneroffset = $('.banner .nav-primary').outerHeight() + 30;
  } else {
    banneroffset = $('.banner').outerHeight();
  }
    $('html, body').animate({
        scrollTop: $('.overzicht').offset().top - banneroffset
    }, 600);

    console.log(banneroffset);
}

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

function blokkenheight() {
   
  $('.drie-list').each(function(){
  
    $('.drie-list-block',this).height('auto');

        var highestBox = 0;
        $('.drie-list-block', this).each(function(){

            if($(this).height() > highestBox) {
               highestBox = $(this).height(); 
             }
        });  

        $('.drie-list-block',this).height(highestBox);

  });


  $('.project-list').each(function(){
  
    $('.project-list-block-header',this).height('auto');

        var highestBox = 0;
        $('.project-list-block-header', this).each(function(){

            if($(this).height() > highestBox) {
               highestBox = $(this).height(); 
             }
        });  

        $('.project-list-block-header',this).height(highestBox);

  });
}

blokkenheight();

$(window).resize(function() {
  blokkenheight();

});

 /**
     * Retrieve posts
     */
    function get_posts($params) {

      $container = $('#container-async');
      $content   = $container.find('.content');
      $status    = $container.find('.status');

      $status.text('Loading posts ...');

      $.ajax({
                  url: bobz.ajax_url,
                  data: {
                    action: 'do_filter_posts',
                    nonce: bobz.nonce,
                    params: $params
                  },
                  type: 'post',
                  dataType: 'json',
                  success: function(data, textStatus, XMLHttpRequest) {
                
                    if (data.status === 200) {
                      $content.html(data.content);
                    }
                    else if (data.status === 201) {
                      $content.html(data.message);  
                    }
                    else {
                      $status.html(data.message);
                    }
               },
               error: function(MLHttpRequest, textStatus, errorThrown) {

          $status.html(textStatus);
          
          /*console.log(MLHttpRequest);
          console.log(textStatus);
          console.log(errorThrown);*/
               },
        complete: function(data, textStatus) {
          
          msg = textStatus;

                if (textStatus === 'success') {
                  msg = data.responseJSON.found;
                }

                $status.text('Posts found: ' + msg);
                
                /*console.log(data);
                console.log(textStatus);*/

                blokkenheight();
              }
          });

    }

    /**
     * Bind get_posts to tag cloud and navigation
     */
    $('#container-async').on('click', 'a[data-filter], .pagination a', function(event) {
      if(event.preventDefault) { event.preventDefault(); }

      $this = $(this);

      /**
       * Set filter active
       */
      if ($this.data('filter')) {
        $this.closest('ul').find('.active').removeClass('active');
        $this.parent('li').addClass('active');
        $page = $this.data('page');
      }
      else {
        /**
         * Pagination
         */
        $page = parseInt($this.attr('href').replace(/\D/g,''));
        $this = $('.nav-filter .active a');
        $('html,body').animate({scrollTop: $("#container-async").offset().top - $(".banner").outerHeight() }, 300);
      }
      

          $params    = {
            'page' : $page,
            'tax'  : $this.data('filter'),
            'term' : $this.data('term'),
            'qty'  : $this.closest('#container-async').data('paged'),
            'ptype' : $this.closest('#container-async').data('ptype'),
          };

          // Run query
          get_posts($params);


          
    });
    
    $('a[data-term="all-terms"]').trigger('click');

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
